<template>
  <div id='shop'>
    <div class='banner_1'>
      <div class='swiper_content'>
        <div v-if='logoList.length > 0'>
          <a :href='logoList[0].link_url&&logoList[0].link_url.indexOf("http") >= 0  ? logoList[0].link_url : null' target='_blank'><img :src='logoList[0].img'/></a>
        </div>
      </div>
    </div>
    <div class='park_wrapper'>
      <div class='park_wrapper_inner'>
        <div class='part_title'>{{ $t('recruit.join') }}</div>
        <div class='park_content'>
          <div class='park_content_left'>
            <img v-if='positionInfo && positionInfo.jion_us_img' :src='positionInfo.jion_us_img' alt=''>
          </div>

          <div class='park_content_right'>
            <el-divider></el-divider>
            <div class='part_text_content'>
             {{positionInfo && positionInfo.join_us_desc ? positionInfo.join_us_desc:''}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    宅机弟自孵化账号-->
    <div class='head-info-item' style='background-color: #F5F5F5;'>
      <!--      <div class='rectangle-position'>-->
      <!--        <img src='../assets/rectangle.png' alt=''>-->
      <!--      </div>-->
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{$t('recruit.visit') }}</span>
        </div>
        <div class='shop-content-info'>
          <div class="baseContent">
            <div class="prev">
              <i class='el-icon-arrow-left'  @click="arrowClick('prev')" />
            </div>
          <el-carousel  :autoplay="isAutoplay" arrow="never" indicator-position="none" @change='carouselChange' :interval='4500' @mouseenter.native='mouseenterInto' @mouseleave.native='mouseleaveInto' setActiveItem='0' ref="cardShow">
            <el-carousel-item v-for="(item,index) in self_incubation" :key='index' >
<!--              <div v-if='item.welfare'>-->
              <div class='recruit_slide'>
                <div class='recruit_slide_left'>
                  <div class='recruit_slide_left_title'>{{item.title ? item.title : ''}}</div>
                  <div>
                    <div v-html="item.content ? item.content : ''"></div>
                  </div>
                  <!--                     @click.stop='gotoSign(item)'-->
                  <el-button class='recruit_slide_span' v-if='item.welfare' @click.stop='gotoSign(item)'>{{$t("recruit.signUp")}}</el-button>
                </div>
                <div class='recruit_slide_right' v-if='item.welfare'>
                  <div v-html="item.welfare ? item.welfare : ''"></div>
                </div>

              </div>
<!--              </div>-->
<!--              <div v-else>-->
<!--                <div class='recruit_slide'>-->
<!--                  <div class='recruit_slide_left'>-->
<!--                    <div class='recruit_slide_left_title'>{{item.title ? item.title : ''}}</div>-->
<!--                    <div>-->
<!--                      <div v-html="item.content ? item.content : ''"></div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                     @click.stop='gotoSign(item)'&ndash;&gt;-->
<!--&lt;!&ndash;                    <el-button class='recruit_slide_span' @click.stop='gotoSign(item)'>{{$t("recruit.signUp")}}</el-button>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </el-carousel-item>
            <div class='recruit-item' v-if='isFade'>
              <div class="content-s">
                <div class='form-item-s'>
                  <div class="name-d">{{$t("LIVE.filling")}}</div>
                  <el-form ref='form' :model="form" :rules="rules" label-position="left">
                    <div class="back-s">
                      <el-form-item label="" prop='name'>
                        <el-input v-model="form.name" size='mini' :placeholder='$t("LIVE.name")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='tel'>
                        <el-input v-model="form.tel" size='mini' :placeholder='$t("LIVE.phone")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='postion'>
                        <el-input v-model="form.postion" size='mini' disabled :placeholder='$t("LIVE.postion")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='wechat'>
                        <el-input v-model="form.wechat" size='mini' :placeholder='$t("LIVE.wechat")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='city'>
                        <el-input v-model="form.city" size='mini' :placeholder='$t("LIVE.city")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='email'>
                        <el-input v-model="form.email" size='mini' :placeholder='$t("LIVE.email")'></el-input>
                      </el-form-item>
                    </div>

                    <el-form-item label-width='0' style='text-align: center'>
                      <el-button type="primary" size='small' @click="onSubmit('form')">{{$t("LIVE.submitText")}}</el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <!--            <div class='data-see'>-->
                <!--              &lt;!&ndash;          <img src='../assets/live-recruit.png' alt='' class="image-platform">&ndash;&gt;-->
                <!--              &lt;!&ndash;          <div class='title-name'>在我的平台 看你的未来</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;          <div class='platform-contents'>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>头部公会</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>专业顶尖团队</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>整合孵化</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>爆款内容打造</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>专业培训</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>艺人主播孵化</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>资源推广</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>优质资源扶持</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;          </div>&ndash;&gt;-->
                <!--            </div>-->
              </div>
            </div>
            <div class='recruit-item2' v-if='welfare=="isWelfare"'>
              <div class="content-s">
                <div class='form-item-s'>
                  <div class="name-d">{{$t("LIVE.filling")}}</div>
                  <el-form ref='form2' :model="form2" :rules="rules" label-position="left">
                    <div class="back-s">
                      <el-form-item label="" prop='name'>
                        <el-input v-model="form2.name" size='mini' :placeholder='$t("LIVE.name")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='tel'>
                        <el-input v-model="form2.tel" size='mini' :placeholder='$t("LIVE.phone")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='postion'>
                        <el-input v-model="form2.postion" size='mini' disabled :placeholder='$t("LIVE.postion")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='wechat'>
                        <el-input v-model="form2.wechat" size='mini' :placeholder='$t("LIVE.wechat")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='city'>
                        <el-input v-model="form2.city" size='mini' :placeholder='$t("LIVE.city")'></el-input>
                      </el-form-item>
                    </div>
                    <div class="back-s">
                      <el-form-item label="" prop='email'>
                        <el-input v-model="form2.email" size='mini' :placeholder='$t("LIVE.email")'></el-input>
                      </el-form-item>
                    </div>

                    <el-form-item label-width='0' style='text-align: center'>
                      <el-button type="primary" size='small' @click="onSubmit('form2')">{{$t("LIVE.submitText")}}</el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <!--            <div class='data-see'>-->
                <!--              &lt;!&ndash;          <img src='../assets/live-recruit.png' alt='' class="image-platform">&ndash;&gt;-->
                <!--              &lt;!&ndash;          <div class='title-name'>在我的平台 看你的未来</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;          <div class='platform-contents'>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>头部公会</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>专业顶尖团队</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>整合孵化</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>爆款内容打造</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>专业培训</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>艺人主播孵化</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>资源推广</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;            <div>优质资源扶持</div>&ndash;&gt;-->
                <!--              &lt;!&ndash;          </div>&ndash;&gt;-->
                <!--            </div>-->
              </div>
            </div>
          </el-carousel>
            <div class="prev">
              <i class='el-icon-arrow-right'  @click="arrowClick('next')" />
            </div>
          </div>
        </div>

      </div>
      <!--      <div class='rectangle-right'>-->
      <!--        <img src='../assets/rectangle.png' alt=''>-->
      <!--      </div>-->
    </div>
    <div class='brand_wrapper'>
      <div class='brand_title'>{{$t('recruit.HuaxingCulture')}}</div>
      <div class='brand_content'>
<!--        <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">-->
        <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">
          <ul class="item">
            <li v-for="(item ,index) in brand_list" style='margin-top: 30px' :key='index'>
              <img :src='item.img' />
            </li>
          </ul>
        </vue-seamless-scroll>
<!--        <vue-seamless-scroll :data="brand_list" :class-option="defaultOption"  class="seamless-warp2">-->
<!--          <ul class="item">-->
<!--            <li v-for="(item , index) in brand_list" v-if='index % 2 == 0' style='margin-top: 10px'>-->
<!--              <img :src='item.img' />-->
<!--            </li>-->
<!--          </ul>-->
<!--        </vue-seamless-scroll>-->
      </div>
    </div>

  </div>
</template>

<script>
  // import homeBanner from '../components/homeBanner'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import Swiper from 'swiper'
  import { Logo, index_List } from '@/api/banner'
  import { getShopBanner, shopJoin, getBrand } from '@/api/shop'
  import { recruitCultureList,recruitJoinUs,recruitPosition ,addInfo_position} from '@/api/recruit'

  export default {
    name: 'shop',
    components:{
      vueSeamlessScroll
      // homeBanner
    },

    data(){
      return{
        newsList: [
          {
            'img': 'https://img0.baidu.com/it/u=617215503,188424665&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=878588444,1785929995&fm=253&fmt=auto&app=138&f=JPG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=4276295978,1564416943&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=503580633,3567414311&fm=253&fmt=auto&app=120&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=417744379,2733026919&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=3793272314,3579832883&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=365019288,3680240542&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=2878383006,2396702772&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=4110101154,77755075&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=2413108766,1138688916&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2246582671,1076022454&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2439673203,2602330429&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=4087371483,2459403015&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=4004226857,4015547609&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img0.baidu.com/it/u=728270901,3143689237&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=166996070,3813994298&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=1229670147,54142161&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=3329779680,1730727527&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=95201979,4265305100&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=2667643370,4182542990&fm=253&fmt=auto&app=138&f=GIF?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=721165360,1838369749&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img1.baidu.com/it/u=3024818213,924427534&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=2279560675,1435387031&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },
          {
            'img': 'https://img2.baidu.com/it/u=1775013149,858928440&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=2525444497,3926769801&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=1622699731,2387912926&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2603763568,553613880&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=897610780,2733025773&fm=253&fmt=auto&app=138&f=PNG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=1671708499,2457454968&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=2966861195,1513133659&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img0.baidu.com/it/u=3619946645,769941788&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2568346834,347660496&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=2725804151,3072200411&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': 'https://img1.baidu.com/it/u=596175957,2171475362&fm=253&fmt=auto&app=138&f=JPEG?w=120&h=120'
          },   {
            'img': '1111111'
          },
        ],
        logoList:[],
        swiper: {
          loop: true, // 循环模式选项

          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination.incubation-s',
            bulletClass : 'my-bullet-2',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },
          navigation: {
            nextEl: '.swiper-button-next.next-right1',
            prevEl: '.swiper-button-prev.prev-left1',
          },
          uniqueNavElements: false,
          autoplay: true,
          initialSlide :0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true,//修改swiper的父元素时，自动初始化swiper
          // on:{
          //   click: function(e){
          //     // let _this=_this
          //     console.log('------------eee',e)
          //     let url = e // jumpurl是在swiper-slide中动态绑定的data-jumpUrl属性，值是从后台获取的跳转链接
          //     this.gotoSign()
          //   },
          // },
        },
        swiper2: {
          direction: 'vertical', // 垂直切换选项
          loop: true, // 循环模式选项
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination.yanxuan_pagination',
            bulletClass : 'my-bullet-shop',//需设置.my-bullet样式
            bulletActiveClass: 'my-bullet-active',
            clickable :true,
          },
          navigation: {
            nextEl: '.swiper-button-next.swiper-button-bottom',
            prevEl: '.swiper-button-prev.swiper-button-top',
          },
          height: 460,//你的slide高度 强制性 垂直方向生效
          effect: 'coverflow', //进出动画
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          //longSwipesRatio: 0.2, //滑动多少就可以滑动
          coverflowEffect: {
            slideShadows: false, // 页面阴影效果
            rotate: 0,// 旋转的角度
            stretch: 510,// 拉伸 图片间左右的间距和密集度
            depth: 0,// 深度 切换图片间上下的间距和密集度
            modifier: .8,// 修正值 该值越大前面的效果越明显
          },
          slidesPerColumn: 1, //竖着展示2张图片
          slidesPerGroup: 1,
          on: {
            progress: function (progress) {
              for (let i = 0; i < this.slides.length; i++) {
                var slide = this.slides.eq(i);
                var slideProgress = this.slides[i].progress;
                slide.css('opacity', 1);
                if (slideProgress == -1) {
                  slide.css('opacity', 0.5);
                }
                if (slideProgress == -2) {
                  slide.css('opacity', 0.3);
                }
                if (slideProgress <= -3 || slideProgress >= 1) {
                  slide.css('opacity', 0);
                }
              }
            },
          },
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          initialSlide :0,
          observer:true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents:true//修改swiper的父元素时，自动初始化swiper
        },
        form: {
          name: '', // 品牌/商品
          tel: '', // 合作意向主播
          postion: '', // 合作需求简介
          city: '', // 是否为品牌直营
          wechat: '', // 微信号
          email: '', // 联系电话
        },
        form2: {
          name: '', // 品牌/商品
          tel: '', // 合作意向主播
          postion: '', // 合作需求简介
          city: '', // 是否为品牌直营
          wechat: '', // 微信号
          email: '', // 联系电话
        },
        positionInfo:{

        },
        self_incubation: [], // 宅机弟自孵化账号
        brand_list: [], // 合作品牌
        defaultOption:{
          step: 0.8, // 数值越大速度滚动越快
          limitMoveNum: 2,
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 2, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: -0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        },
        isFade:false,
        welfare:'',
        edit_id:'',
        isAutoplay:true
      }
    },
    computed: {
      rules:function(){
        let data ={
          name: [
            { required: true, message: this.$t('LIVE.rules_name'), trigger: 'blur' }
          ],
          tel: [
            { required: true, message: this.$t('LIVE.rules_phone'), trigger: 'blur' }
          ],
          postion: [
            { required: true, message: this.$t('LIVE.rules_position'), trigger: 'blur' }
          ],
          wechat: [
            { required: true, message: this.$t('LIVE.rules_wechat'), trigger: 'blur' }
          ],
          city: [
            { required: true, message: this.$t('LIVE.rules_city'), trigger: 'blur' }
          ],
          email: [
            { required: true, message: this.$t('LIVE.rules_email'), trigger: 'blur' }
          ],


        }
        return data
      },

    },
    mounted() {
      this.get_Logo()
      this.get_index_List() // 商务合作背景图
      this.recruit_Position() // 1宅机弟自孵化账号
      this.get_brand() // 合作品牌
      let  language=localStorage.getItem('locale')
    },
    methods: {
      arrowClick(val) {
        if(val === 'next') {
          this.$refs.cardShow.next()
        } else {
          this.$refs.cardShow.prev()
        }
        let data={
          name: '', // 品牌/商品
          tel: '', // 合作意向主播
          postion: '', // 合作需求简介
          city: '', // 是否为品牌直营
          wechat: '', // 微信号
          email: '', // 联系电话
        }
        this.$set(this,'form',data)
        this.$set(this,'form2',data)
      },
      mouseenterInto:function(){
        this.$set(this,'isAutoplay',false)
      },
      mouseleaveInto:function(){
        this.$set(this,'isAutoplay',true)
      },
      carouselChange:function(index){
        let list=this.self_incubation
        let data={
          name: '', // 品牌/商品
          tel: '', // 合作意向主播
          postion: '', // 合作需求简介
          city: '', // 是否为品牌直营
          wechat: '', // 微信号
          email: '', // 联系电话
        }
        this.$set(this, 'welfare', '')
        this.$set(this,'form',data)
        if(!list[index].welfare){
          this.$set(this, 'welfare', 'isWelfare')
          this.$set(this.form2,'postion',list[index].title)
        }else {
          this.$set(this, 'welfare', '')
          this.$set(this.form,'postion',list[index].title)
        }
        this.isFade=false
        this.edit_id=list[index].id
        // this.$set(this,'form',data)
      },
      gotoSign:function(params){
        // console.log('params',params)
        let list=this.self_incubation
        this.$set(this.form,'postion',params.title)
        this.edit_id=params.id
        this.isFade=!this.isFade
        list.forEach(item=>{
          if(item.id==params.id){
            item.isFade=!item.isFade
          }
        })
        this.$set(this,'self_incubation',list)
      },
      gotoMore:function(){
        this.$router.push({
          path:'/aboutUs'
        })
      },
      get_Logo: function() {
        Logo(8).then(resp => {
          if (resp.status== 200) {
            this.$set(this, 'logoList', resp.data)
          }
        }).catch(error => {

        })
      },
      // 商务合作背景图
      get_index_List: function() {
        recruitJoinUs().then(resp => {
          if (resp.status== 200) {
            this.$set(this, 'positionInfo', resp.data)
          }
        }).catch(error => {
        })
      },
      recruit_Position: function() {
        recruitPosition().then(resp => {
          if(resp.status== 200) {
            resp.data.forEach(item=>{
              if(item.welfare){
                item.isFade=false
              }
            })
            if(!resp.data[0].welfare){
              this.$set(this, 'welfare', 'isWelfare')
            }
             this.$set(this, 'self_incubation', resp.data)
              this.$nextTick(()=>{
                let mySwiper = new Swiper ('.swiper1', this.swiper)
              })
          } else {
            this.$message(resp.data.msg)
          }
        }).catch(err => {
        })
      },
      // 华星文化
      get_brand: function() {
        recruitCultureList().then(resp => {
          if(resp.status== 200) {
            this.$set(this, 'brand_list', resp.data)
          } else {
            this.$message(resp.msg)
          }
        }).catch(err => {
        })
      },
      onSubmit: function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(formName=='form'){
              let data={
                position_id:this.edit_id,
                user_name:this.form.name,
                phone:this.form.tel,
                wechat:this.form.wechat,
                city:this.form.city,
                email:this.form.email,
              }
              addInfo_position(data).then(resp => {
                if(resp.status== 200) {
                  this.$message({
                    message: '提交成功！请等待HR通知。',
                    type: 'success'
                  });
                  this.edit_id=''
                  this.form = {
                    name: '', // 品牌/商品
                    tel: '', // 合作意向主播
                    postion: '', // 合作需求简介
                    city: '', // 是否为品牌直营
                    wechat: '', // 微信号
                    email: '', // 联系电话
                  }
                } else {
                  this.$message(resp.data.msg)
                }
                this.isFade=false
              }).catch(err => {
              })
            }else {
              let data={
                position_id:this.edit_id,
                user_name:this.form2.name,
                phone:this.form2.tel,
                wechat:this.form2.wechat,
                city:this.form2.city,
                email:this.form2.email,
              }
              addInfo_position(data).then(resp => {
                if(resp.status== 200) {
                  this.$message({
                    message: '提交成功！请等待HR通知。',
                    type: 'success'
                  });
                  this.form2 = {
                    name: '', // 品牌/商品
                    tel: '', // 合作意向主播
                    postion: this.form2.postion, // 合作需求简介
                    city: '', // 是否为品牌直营
                    wechat: '', // 微信号
                    email: '', // 联系电话
                  }
                } else {
                  this.$message(resp.data.msg)
                }
                this.isFade=false
              }).catch(err => {
              })
            }
            // let data={
            //   position_id:this.edit_id,
            //   user_name:this.form.name,
            //   phone:this.form.tel,
            //   wechat:this.form.wechat,
            //   city:this.form.city,
            //   email:this.form.email,
            // }

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped lang='scss'>
  .head-info-item{
  //display: flex;
    position: relative;
    padding: 30px 0;
    /*padding-bottom:120px;*/
  .header-anchor{
    width: 1100px;
    height: 600px;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;

  //position: relative;
    line-height: 30px;
  .shop-content-info{
  //background: rgba(213, 71, 36, 0.1);
  //box-shadow: 0px 8px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 40px;
    width: 1100px;
    height: 540px;
    overflow: hidden;
    margin: auto;
  }
  }

  .lefts{
    left: 140px;
    bottom: 240px;
  }
  .rectangle-right{
    position: absolute;
    top: 80px;
    right: 100px;
  img{
    width: 106px;
    height: 73px;
  }
  }
  .left-s{
    position: absolute;
    left: 0;
    top: -200px;
    opacity: 0.3;
  img{
    width: 355px;
    height: 657px;
  }
  }
  .rights{
    right: 160px;
  }
  .swiper-slide{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #666666;
    position: relative;
  }
  .anchor-list{
    padding: 30px 0 0 70px;
  }
  .anchor-list div:nth-child(1){
    font-size: 20px;
    color: #333333;
    line-height: 50px;
  }
  .anchor-list div:nth-child(3){
    margin: 50px 0 150px 0;
  }
  .anchor-list span:nth-child(4){
    background: #D54724;
    border-radius: 23px;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
  }
  .anchor-avatar{
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
  .yanxuan-list{
    height: auto;
    width: 98%;
  }
  .lists-con{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #FFFFFF;
  }
  .yanxuan-avatar{
    width: 100%;
    height: 446px;
  }
  .yanxuan-name{
    font-size: 18px;
    line-height: 47px;
    padding-left: 76px;
  }
  .yx-number{
    padding-left: 76px;
    line-height: 25px;
  }
  .yx-number > div:nth-child(2) {
    margin-bottom: 10px;
  }
  .yx-number > span:nth-child(3) {
    background: #FFFFFF;
    color: #D54724;
    padding: 5px 5px;
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: bold;
  }
  .hot-case{
    width: 87%;
    margin: auto;
    height: 567px;
    overflow: hidden;
  .swiper-button-prev{
    left: 20px;
  }
  .swiper-button-next{
    right: 20px;
  }
  .swiper-slide{
  //width: 1004px !important;
  }
  .avatar-explosion{
    width: 1004px;
    height: 100%;
    border-radius: 40px;
  }
  }
  .avatar2{
    width: 100%;
    height: 100%;
  //box-shadow: 0px 3px 3px 0px rgba(64, 64, 64, 0.1);
    border-radius: 40px;
  }
  .hot-information{
    width: 100%;
    margin: auto;
    height: 234px;
    overflow: hidden;
  .swiper-button-left{
    left: -8.5%;
  }
  .swiper-button-right{
    right: -8%
  }
  .swiper-slide-duplicate-next{
    opacity: 0 !important;
  }
  .swiper-slide-duplicate-prev{
    opacity: 0 !important;
  }
  .swiper-wrapper{
    align-items: center;
  }
  .swiper-slide-prev,.swiper-slide-next{
    width: 312px !important;
    height: 215px !important;
  }
  .swiper-slide-active {
    width: 512px !important;
    height: 234px !important;
    z-index: 33 !important;
  }
  }
  }
  .brand_wrapper{
    width: 100%;
    margin-top: 40px;
  .brand_title{
    text-align: center;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .brand_content{
    width: 100%;
    background-color: #fff;
    height: 380px;
  .seamless-warp2{
    width: 100%!important;
    overflow: hidden;
  .item{
    width: 100%!important;
  li{
    width: 524px;
    height: 350px;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 40px;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 30px;
  /*.wrapper_img{*/
  /* width: 120px;*/
  /*  height: 120px;*/
  img{
    width: 524px;
    height: 350px;
    border-radius: 40px;
  }
  /*}*/
  }
  }
  }

  }
  }
  .banner_1 {
    width: 100%;
    height: 700px;

  .swiper_content {
    width: 100%;
    height: 700px;

  .swiper-container {
    width: 100%;
    height: 700px;

  .swiper-wrapper {
    width: 100%;
    height: 700px;

  .swiper-slide {
    width: 100%;
    height: 700px;

  img {
    width: 100%;
    height: 700px;
  }
  }
  }
  }
  img {
    width: 100%;
    height: 700px;
  }
  }
  }
  .recruit-item{
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 40px;
    height: 540px;
    width: 300px;
  .content-s{
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    z-index: 2;
    /*left: 22%;*/
  }
  .form-item-s{
    box-sizing: border-box;
    width: 300px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 540px;
    padding: 15px;
  .back-s{
  //background: #F19F8A;
  //border-radius: 25px;
  //padding: 0 10px 0 10px;
  }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .data-see{
    padding: 10px;
    margin-left: 105px;
  .image-platform{
    width: 505px;
    height: 303px;
  }
  }
  }
  .recruit-item2{
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 40px;
    height: 540px;
    width: 300px;
  .content-s{
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    border-radius: 40px;
    z-index: 2;
    /*left: 22%;*/
  }
  .form-item-s{
    box-sizing: border-box;
    width: 300px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(170, 169, 169, 0.44);
    border-radius: 40px;
    height: 540px;
    padding: 15px;
  .back-s{
  //background: #F19F8A;
  //border-radius: 25px;
  //padding: 0 10px 0 10px;
  }
  }
  .form-item-s .name-d {
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .data-see{
    padding: 10px;
    margin-left: 105px;
  .image-platform{
    width: 505px;
    height: 303px;
  }
  }
  }
  .swiper1 {
    width: 100%;
    height: 428px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .swiper2 {
    width: 95%;
    height: 576px;
    --swiper-theme-color: rgba(232, 75, 36, 1);
    --swiper-navigation-size: 40px;/* 设置按钮大小 */
  }
  .map-container {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    background-color: rgba(213, 71, 36, 0.1);
  }
  .know_us {
    text-align: left;
    position:absolute;
    z-index: 999;
    bottom: 35px;
    left: 80px;
  .know_more {
    background-color: #D54724;
    color: #fff;
    cursor: pointer;
  }
  .know_more:hover{
    background-color: rgba(213,71,26,0.5);

  }
  .know_more_nodata{
    background-color: rgba(213,71, 36, 0.5);
    color: #fff;
  //cursor:not-allowed;
  }
  }

  .shop-content-info .el-button {
    border: none;
    outline: none;
    border-radius: 50px;
  }

  .prev-left1{
    left:-6%;
    top: 50%;
  }
  .next-right1{
    right: -6%;
    top: 50%;
  }

  .prev-left2{
    left:20%;
  }
  .next-right2{
    right: 20%;
  }

  .park_wrapper {
    width: 100%;
    padding: 67px 0px 0 0;
    position: relative;

  .park_wrapper_inner {
    box-sizing: border-box;
    width: 1364px;
    /*height: 805px;*/
    margin-left: calc(50% - 682px);
  //background-color: rgba(213, 71, 36, 1);
    color: #333;
    padding: 40px 75px 0 100px;

  .part_title {
    text-align: center;
    font-size: 26px;
    font-family: SourceHanSansCNVF;
    font-weight: 400;
    color: #333;
    line-height: 50px;
  }

  .park_content {
    height: 360px;
    margin:60px 0px;
    display: flex;
    flex-flow: row nowrap;
    just-content:between-around;
  .park_content_left {
    width: 596px;
    height: 360px;
    margin-right:46px;
  img{
    width: 596px;
    height: 360px;
  }
  }

  .park_content_right {
    width: 550px;
  .el-divider--horizontal{
    height: 1px;
    margin-top: 0px!important;
    margin-bottom: 15px!important;
    background: #DBDBDB;
  }
  .part_text_content{
    color: #666;
    font-size: 16px;
    line-height: 36px;
  }
  }
  }
  }
  }
  .el-carousel{
    width: 960px;
    height: 540px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
  }
  .el-carousel__container{
    width: 960px;
    height: 540px;
    border-radius: 40px;
  }
  .el-carousel__item{
    width: 960px;
    height: 540px;
    border-radius: 40px;
  }
  .recruit_slide{
    position: relative;
    box-sizing:border-box;
    width: 960px;
    height: 540px;
    background-color: #fff;
    background-image: url("../assets/bg.png") ;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 100%;
    border-radius: 40px;
    display: flex;
    flex-flow: row nowrap;
    .recruit_slide_left{
      width: 660px;
      box-sizing:border-box;
      padding:15px 20px;
      position:relative;
    .recruit_slide_span{
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 110px;
      height: 34px;
      /*line-height: 34px;*/
      background: #D54724;
      color: #fff;
      border-radius: 17px;
      cursor: pointer;
      z-index: 6666;
      text-align: center;
    }
      .recruit_slide_left_title{
        font-size: 26px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
      }
      .recruit_slide_left_tsub{
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
      }
      .recruit_slide_left_text{
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    .recruit_slide_right{
      width: 300px;
      height: 100%;
      background-color: #D54724;
      color:#fff;
      border-radius: 40px;
      padding: 20px 15px;
      box-sizing:border-box;
      .recruit_slide_right_title{
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #FEFEFE;
        line-height: 50px;
      }
      .recruit_slide_right_text{
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
      }
    }
  }
  .wrapper_form{
    width: 300px;
    height: 540px;
    border-radius: 40px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 666;
  }


  .excellentCases {
    max-width: 1426px;
    height: 530px;
    margin: 0 auto;
    background: #323233;
  .title{
    font-size: 34px;
    font-weight: 500;
    line-height: 34px;
    color: #FFFFFF;
    text-align: center;
    margin: 56px 0 27px 0;
  }
  .baseContent{
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    /*position: relative;*/
  .prev{
    position: absolute;
    left: 46px;
    top:34%;
  img{
    width: 75px;
    height: 74px;
  }
  }
  .next{
    position: absolute;
    right: 46px;
    top:34%;
  img{
    width: 75px;
    height: 74px;
  }
  }
  .carousel{
    width: 960px;
  // height: 540px;
    margin: 0 auto;
    background-color: #cccc;
  deep.el-carousel__container{
    height: 347px;
  }
  deep .el-carousel__indicators--outside{
    background-color: #323233;;
  }
  }
  }
  }
  .baseContent{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .prev{
    width: 46px;
    height: 46px;
    border-radius: 50px;
    color: #D54724;
    line-height: 46px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(213, 71, 36, 0.15);
  i{
    font-size: 46px;
  }
  }
  .prev:hover{
    background-color: #D54724;
    color: #fff;
  }
</style>
