import request from '@/utils/request'

// 电商--1宅机弟自孵化账号 2华星严选 3爆款案例 4近期资讯
export function getShopBanner(data) {
  return request({
    url: '/web/shop/getShopBanner',
    method: 'get',
    params: data
  })
}
// 电商--商务招募
export function shopJoin(data) {
  return request({
    url: '/web/mcn/shopJoin',
    method: 'POST',
    data
  })
}
// 电商--合作品牌
export function getBrand(data) {
  return request({
    url: '/web/brand/getBrand',
    method: 'GET',
    params: data
  })
}
