import request from '@/utils/request'

// mcn--1头部达人 2热门达人 3爆款视频 4广告爆款达人
export function recruitCultureList() {
  return request({
    url: '/web/position/cultureList',
    method: 'get',
  })
}

export function recruitJoinUs() {
  return request({
    url: '/web/position/joinUs',
    method: 'get',
  })
}

export function recruitPosition() {
  return request({
    url: '/web/position/getList',
    method: 'get',
  })
}

export function addInfo_position(data) {
  return request({
    url: '/web/position/addInfo',
    method: 'post',
    data:data
  })
}
